/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import useLocalStorageFetch from '../../../../hooks/localStorageFetch'
import {useSelector} from 'react-redux'
import {getInitials} from '../../../../data/config'
import {toAbsoluteUrl} from '../../../../_ePulse/helpers'
import useApi from '../../../../hooks/useQuery'
import {GET_BUSINESS_DETAIL_BY_ID} from '../GraphQLQueries/Doctor'
import {LoadingSpinner} from '../../../../_ePulse/layout/components/LoadingSpinner'
import {GET_MY_PROFILE} from '../../../common/GraphQLQuries/common'

type Props = {
  className: string
}

const ClinicDetails: React.FC<Props> = ({className}) => {
  const [isloading, setLoading] = useState(false)
  const [businessData, setbusinessData] = useState<any>({})
  const [userDataObj, setUserData] = useState<any>({})
  const navigate = useNavigate()
  const {role} = useLocalStorageFetch()
  const reduxData: any = useSelector((state) => {
    return state
  })

  const {data, loading, error, refetch: refectch6} = useApi(true, GET_BUSINESS_DETAIL_BY_ID, {})

  useEffect(() => {
    if (data) {
      const res = data?.getBusinessDetails
      setbusinessData(res)
    }
    refectch6()
  }, [data])

  const {
    data: userData,
    loading: userLoading,
    error: userError,
    refetch: userRefectch,
  } = useApi(true, GET_MY_PROFILE, {})

  useEffect(() => {
    if (userData) {
      const res = userData?.getMyUserProfile
      setUserData(res)
    }
    userRefectch()
  }, [userData])

  console.log('profilePhoto', userDataObj)

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <LoadingSpinner isLoading={loading} />
      <div className='px-8 border-0 mt-4'>
        <div className='d-flex flex-direcion-row justify-content-between col-12 align-items-center my-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Clinic Details</span>
          </h3>
          {/* <h3 className=' align-items-start flex-column'>
            <span className=' fs-6 fw-normal align-items-start flex-column'>All Options</span>
          </h3> */}
        </div>
        <div className='separator mx-2' />
      </div>
      <div className='d-flex flex-column'>
        <div
          className='d-flex flex-direcion-row card-header border-0 justify-content-between mb-6'
          //   style={{height: '250px'}}
        >
          <div className=' bg-light my-6 col p-2'>
            <div className='d-flex flex-direction-row'>
              {reduxData.auth.profileData.profilePhoto ? (
                <img
                  src={toAbsoluteUrl('/media/avatars/300-14.jpg')}
                  alt=''
                  className='rounded-circle'
                  width={80}
                  height={80}
                />
              ) : (
                <div
                  className='border border-1 border-dark bg-light rounded-circle text-center d-flex justify-content-center align-items-center'
                  style={{height: '70px', width: '70px'}}
                >
                  <span className='fs-1 fw-bold'>
                    {getInitials(
                      `${businessData?.userProfile?.firstName} ${businessData?.userProfile?.lastName}`
                    )}
                  </span>
                </div>
              )}

              <div className='d-flex flex-wrap mx-5 flex-column'>
                <span className='fs-6 '>
                  Associated Doctor-{' '}
                  <span className='fw-bold'>
                    {' '}
                    {`${userDataObj?.userProfile?.firstName} ${userDataObj?.userProfile?.lastName}`}
                  </span>
                </span>
                <span className='text-black card-label fs-5 mb-1'>
                  Phone No.- <span className='fw-bold'>{userDataObj?.mobileNumber}</span>
                </span>
                <span className='text-black card-label fs-5 mb-1'>
                  Speciality.-{' '}
                  <span className='fw-bold'>
                    {userDataObj?.userProfile?.userTechDetails?.specialty}
                  </span>
                </span>
              </div>
            </div>
          </div>
          {!reduxData.auth.isLinkedDoctor && (
            <div className='col bg-white my-6'>
              <Link
                to={`/${role}/staff-managment`}
                className='d-flex align-items-sm-center mb-2 bg-light'
              >
                <div className='symbol symbol-50px me-5'>
                  <span className='symbol-label'>
                    <img
                      src={toAbsoluteUrl('/media/ePulse/users.svg')}
                      className='h-50 align-self-center'
                      alt=''
                    />
                  </span>
                </div>

                <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                  <div className='flex-grow-1 me-2'>
                    <a href='#' className='text-gray-800  fs-6 fw-bold'>
                      View Staff Members
                    </a>
                  </div>

                  <span className='badge badge-light fw-bold my-2'>
                    <img
                      src={toAbsoluteUrl('/media/ePulse/rightArrow.png')}
                      className='h-50 align-self-center'
                      alt=''
                    />
                  </span>
                </div>
              </Link>
            </div>
          )}
        </div>
        <div className='p-2'>
          <div className='d-flex flex-wrap mx-5 flex-column'>
            <span className='fs-6 mb-3'>
              Business Name-{' '}
              <span className='fw-bold'> {`${businessData?.businessProfile?.businessName} `}</span>
            </span>
            <div className='d-flex flex-row '>
              <span className='text-black card-label fs-5 mb-1 col'>
                Phone No.-{' '}
                <span className='fw-bold'>{businessData?.businessProfile?.businessPhone}</span>
              </span>
              <span className='text-black card-label fs-5 mb-1 col'>
                Email.-{' '}
                <span className='fw-bold'>{businessData?.businessProfile?.businessEmail}</span>
              </span>
            </div>

            <div className='d-flex flex-row '>
              <span className='text-black card-label fs-5 mb-1 col'>
                Street.- <span className='fw-bold'>{businessData?.businessAddress?.street}</span>
              </span>
              <span className='text-black card-label fs-5 mb-1 col'>
                City.- <span className='fw-bold'>{businessData?.businessAddress?.city}</span>
              </span>
            </div>
            <div className='d-flex flex-row '>
              <span className='text-black card-label fs-5 mb-1 col'>
                District.-{' '}
                <span className='fw-bold'>{businessData?.businessAddress?.district}</span>
              </span>
              <span className='text-black card-label fs-5 mb-1 col'>
                State.- <span className='fw-bold'>{businessData?.businessAddress?.state}</span>
              </span>
            </div>
            {/* <div className='d-flex flex-row '>
              <span className='text-black card-label fs-5 mb-1 col'>
                ZipCode.- <span className='fw-bold'>{businessData?.businessAddress?.zipCode}</span>
              </span>
              <span className='text-black card-label fs-5 mb-1 col'>
                Country.- <span className='fw-bold'>{businessData?.businessAddress?.country}</span>
              </span>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export {ClinicDetails}
