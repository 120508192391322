/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {useSelector, useDispatch} from 'react-redux'

import {useMutation} from '@apollo/client'

import {useLocation, useNavigate} from 'react-router-dom'
import useMutationApi from '../../../../../hooks/useMutation'
import {LOGOUT, OTP_MUTATION} from '../../../GraphQLQuries/Auth'
import {actionsUsers} from '../../../../../setup/redux/rootReducer'
import {numberMasking} from '../../../../../data/config'
import {LoadingSpinner} from '../../../../../_ePulse/layout/components/LoadingSpinner'

const loginSchema = Yup.object().shape({
  mpin: Yup.string().required('MPIN is required'),
})

const initialValues = {
  mpin: '',
}

export function MPIN() {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const {browserUniqueId, ipAddress, userAgent, browserName, countryCode, mpinLength}: any =
    location?.state
  const userData: any = useSelector((state) => {
    return state
  })

  const [loadingLoader, setLoading] = useState(false)

  const {createData, data, loading, error} = useMutationApi(OTP_MUTATION)

  const [status, setstatus] = useState(false)
  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (
      values
      // , {setStatus, setSubmitting}
    ) => {
      setLoading(true)
      try {
        const mobileNumber = userData.user.mobile_number

        const res = await createData({
          mobileNumber,
          countryCode: parseInt(countryCode),
          otp: values.mpin,
          type: 'M_PIN',
          browserUniqueId: browserUniqueId,
          ipAddress: ipAddress,
          userAgent: userAgent,
          browserName: browserName,
        })

        dispatch(actionsUsers.updateState(res?.data?.verifyWebAdminOtp))
        dispatch(actionsUsers.sessionTrackingStart())
        dispatch(
          actionsUsers.updateUser({
            mobile_number: userData.user.mobile_number,
            _id: res?.data?.verifyWebAdminOtp.userId,
          })
        )
        navigate(`/auth/business`, {
          state: {browserUniqueId, ipAddress, userAgent, browserName},
        })

        setLoading(false)
      } catch (error) {
        setstatus(true)
        setLoading(false)
      }
    },
  })

  const {
    createData: logout,
    loading: loading2,
    error: logoutError,
    data: logoutData,
  } = useMutationApi(LOGOUT)

  const logoutUser = async () => {
    // const response = await logout({logoutType: 'one'})
    dispatch(actionsUsers.logout())
    dispatch(actionsUsers.sessionTrackingStop())
    navigate('/')
  }

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      <div className='login-back-box'>
        <span className='login-back-link' onClick={() => logoutUser()}>
          Back
        </span>
      </div>
      <LoadingSpinner isLoading={loading || loading2} />
      {/* begin::Heading */}
      {/* <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Enter MPIN</h1>
      </div> */}
      {/* begin::Heading */}

      {error && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{error?.message}</div>
        </div>
      )}

      {/* begin::Form group */}
      <div className='fv-row mb-10'>
        <label className='form-label fs-6 fw-bolder text-dark'>
          Hello, {numberMasking(userData.user.mobile_number)}
        </label>
        <input
          placeholder='Enter MPIN'
          {...formik.getFieldProps('mpin')}
          className={clsx('form-control form-control-lg form-control-solid')}
          type='text'
          name='mpin'
          autoComplete='off'
          maxLength={mpinLength}
        />
        {formik.touched.mpin && formik.errors.mpin && (
          <div className='fv-plugins-message-container'>
            <span className='text-danger'>{formik.errors.mpin}</span>
          </div>
        )}
      </div>

      {/* end::Form group */}
      {/* begin::Action */}
      {/* <div className='d-flex flex-row justify-content-between'>
        <button type='button' className='ePulse-btn-secondory' onClick={() => logoutUser()}>
          Logout
        </button> */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          // className='btn btn-lg  w-100 mb-5 rounded-pill text-white'
          className='ePulse-btn-primary'
          //   disabled={formik.isSubmitting || !formik.isValid}
          style={{backgroundColor: '#000FD0'}}
        >
          {!loadingLoader && <span className='indicator-label'>Continue</span>}
          {loadingLoader && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>
      {/* end::Action */}
    </form>
  )
}
