/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import useApi from '../../../../hooks/useQuery'
import {GET_BUSINESS_STEPS, GET_VISTING_HOURS} from '../GraphQLQueries/Dashboard'
import {LoadingSpinner} from '../../../../_ePulse/layout/components/LoadingSpinner'

type Props = {
  className: string
}

const VisitingHours: React.FC<Props> = ({className}) => {
  const [visitingHours, setVisitingHours] = useState<any>([])
  const [businessSteps, setbusinessSteps] = useState<any>([])

  const {data, loading, error, refetch} = useApi(true, GET_VISTING_HOURS, {})
  // const {
  //   data: businessStepData,
  //   loading: businessSteploading,
  //   error: businessStepError,
  //   refetch: businessStepRefetch,
  // } = useApi(true, GET_BUSINESS_STEPS, {})

  useEffect(() => {
    // refetch()
    // businessStepRefetch()
    if (data) {
      const res = data?.getDoctorVisitingHours.weeklySlotsDetails

      setVisitingHours(res)
    }
    // if (businessStepData) {
    //   const res = data?.getDoctorVisitingHours.weeklySlotsDetails
    //   setbusinessSteps(res)
    // }
  }, [data])

  return (
    <div className={`card ${className}`}>
      <LoadingSpinner isLoading={loading} />
      {/* begin::Header */}
      {/* <div className='px-8 border-0 mt-4'>
        <div className='d-flex flex-direcion-row justify-content-between col-12 align-items-center my-2'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>
              Visiting Hours & Business Steps Details
            </span>
          </h3>
        </div>
        <div className='separator mx-2' />
      </div> */}

      <div className='d-flex flex-column p-4'>
        {visitingHours.length ? (
          <>
            <div className='px-3'>
              <span className='fs-3 fw-bold'>Visiting Hours</span>
              <div className='d-flex flex-row  align-items-center my-3'>
                <div className=' col px-2 fw-bold '>Sr. No.</div>
                <div className=' col px-2 fw-bold '>Day of week</div>
                {/* <div className=' col px-2 fw-bold '>Timings</div> */}
                <div className=' col px-2 fw-bold '>Open/Closed</div>
                <div className=' col px-2 fw-bold '>27/7 Open</div>
              </div>
              {visitingHours?.map((input: any, index: any) => {
                console.log('INPUT', index, input)
                return (
                  <div key={index} className='d-flex flex-row align-items-center'>
                    <div className=' col px-2 '>{index + 1}</div>

                    <div className=' col px-2 '>{input?.dayOfWeek}</div>
                    {/* <div className=' col px-2 '>{input?.timings || 'NA'}</div> */}
                    <div className=' col px-2 '>{input?.isClosed ? 'Closed' : 'Open'}</div>
                    <div className=' col px-2 '>{input?.isOpen24Hours ? 'Yes' : 'No'}</div>
                  </div>
                )
              })}
            </div>
            <div className='separator mx-2' />
          </>
        ) : (
          <>null</>
        )}

        {/* {businessSteps.length ? (
          <>
            <div className='px-3'>
              <span className='fs-3 fw-bold'>Business Steps</span>
              <div className='d-flex flex-row  align-items-center my-3'>
                <div className=' col px-2 fw-bold '>Sr. No.</div>
                <div className=' col px-2 fw-bold '>Step Name</div>
                <div className=' col px-2 fw-bold '>Step Number</div>
                <div className=' col px-2 fw-bold '>Screen Name</div>
                <div className=' col px-2 fw-bold '>Completed</div>
                <div className=' col px-2 fw-bold '>Verified</div>
                <div className=' col px-2 fw-bold '>Optional</div>
              </div>
              {visitingHours?.map((input: any, index: any) => (
                <div key={index} className='d-flex flex-row align-items-center'>
                  <div className=' col px-2 '>{index + 1}</div>
                  <div className=' col px-2 '>{input.stepName}</div>
                  <div className=' col px-2 '>{input?.stepNumber}</div>
                  <div className=' col px-2 '>{input?.screenName}</div>
                  <div className=' col px-2 '>{input.isCompleted ? 'Yes' : 'No'}</div>
                  <div className=' col px-2 '>{input.isVerified ? 'Yes' : 'No'}</div>
                  <div className=' col px-2 '>{input.isOptional ? 'Yes' : 'No'}</div>
                </div>
              ))}
            </div>
            <div className='separator mx-2' />
          </>
        ) : (
          <></>
        )} */}
      </div>
    </div>
  )
}

export {VisitingHours}
