/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'

import {useQuery} from '@apollo/client'
import {GET_PATIENT_LIST_PAGINATION} from '../../../../app/MasterModules/DoctorModule/GraphQLQueries/Patients'
import {useNavigate} from 'react-router-dom'
import useLocalStorageFetch from '../../../../hooks/localStorageFetch'
import {LoadingSpinner} from '../../../layout/components/LoadingSpinner'
import useApi from '../../../../hooks/useQuery'

const PatientsQueue = () => {
  const navigate = useNavigate()
  const {role} = useLocalStorageFetch()
  const [listing, setList] = useState([])

  const {
    data,
    loading,
    error,
    refetch: refectch6,
  } = useApi(true, GET_PATIENT_LIST_PAGINATION, {page: 1, limit: 10})

  useEffect(() => {
    if (data) {
      setList(data?.getBusinessPatientPaginationList?.data)
    }
  }, [data, listing])

  return (
    <div className='card card-xl-stretch mb-xl-8'>
      <LoadingSpinner isLoading={loading} />
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold text-dark'>Patients Queue</span>
        </h3>
        <div
          className='card-toolbar'
          style={{cursor: 'pointer'}}
          onClick={() => {
            navigate(`/${role}/patients`)
          }}
        >
          View All
        </div>
      </div>

      <div className='card-body pt-5' style={{height: '250px', overflow: 'scroll'}}>
        {listing.length ? (
          <>
            <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
              <div className='flex-grow-1 me-2'>
                <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>Patient Name</span>
              </div>

              <span className='badge badge-light fw-bold my-2 text-dark'>
                Patient mobile Number
              </span>
            </div>
            {listing.map((patient: any) => {
              return (
                <div className='d-flex align-items-center flex-row-fluid flex-wrap'>
                  <div className='flex-grow-1 me-2'>
                    <span className='text-gray-800 text-hover-primary fs-6 fw-bold'>
                      {patient.firstName} {patient.lastName}
                    </span>
                    {/* <div className='d-flex col-4 bg-warning align-items-center justify-content-center rounded-pill'>
                      <span className='text-black fw-semibold d-block fs-8'>Consultation</span>
                    </div> */}
                  </div>

                  <span className='badge badge-light fw-bold my-2 text-primary'>
                    {patient.mobileNumber}
                  </span>
                </div>
              )
            })}
          </>
        ) : (
          <div className='text-center fs-5 fw-semibold'>No data found</div>
        )}
      </div>
    </div>
  )
}

export {PatientsQueue}
