/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {gql, useQuery} from '@apollo/client'
import '../../../../../../../App.css'

import {Modal} from 'react-bootstrap'
import {ToastMessageContainer} from '../../../../../../../_ePulse/layout/components/ToastMessageContainer'
import useApi from '../../../../../../../hooks/useQuery'
import {GET_PATIENT_BY_ID} from '../../../../GraphQLQueries/Patients'
import {LoadingSpinner} from '../../../../../../../_ePulse/layout/components/LoadingSpinner'

export function ViewPatientModal(props: any) {
  const [patientData, setpatientData] = useState<any>()

  const {
    data: data6,
    loading,
    error,
    refetch: refectch6,
  } = useApi(true, GET_PATIENT_BY_ID, {
    id: props.idToEdit,
  })

  useEffect(() => {
    if (data6) {
      const res = data6?.getBusinessUserProfile
      setpatientData(res)
    }
    refectch6()
  }, [data6])

  return (
    <div>
      <ToastMessageContainer />
      <Modal
        show={props.modalState}
        dialogClassName='ePulse-modal ePulse-modal-50w'
        onHide={() => props.closeModal(false)}
        backdrop='static'
      >
        <Modal.Header closeButton>
          <span className='fs-3 fw-bold'>View Patient</span>
        </Modal.Header>
        <Modal.Body className='container'></Modal.Body>
        <div className='ePulse-modal-content'>
          <LoadingSpinner isLoading={loading} />
          <div className='bg-white rounded p-3'>
            <div className='d-flex flex-column justify-content-between mb-4'>
              <div className='row'>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>First Name:</span>
                  <span className='fs-5 fw-bold mb-2 col'>
                    {patientData?.userProfile?.firstName}
                  </span>
                </div>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>Last Name:</span>
                  <span className='fs-5 fw-bold mb-2 col'>
                    {patientData?.userProfile?.lastName}
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>Gender:</span>
                  <span className='fs-5 fw-bold mb-2 col'>
                    {patientData?.userProfile?.gender || 'NA'}
                  </span>
                </div>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>Email:</span>
                  <span className='fs-5 fw-bold mb-2 col'>{patientData?.email || 'NA'}</span>
                </div>
              </div>
              <div className='row'>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>Country Code:</span>
                  <span className='fs-5 fw-bold mb-2 col'>{patientData?.countryCode || 'NA'}</span>
                </div>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>Mobile Number:</span>
                  <span className='fs-5 fw-bold mb-2 col'>{patientData?.mobileNumber || 'NA'}</span>
                </div>
              </div>

              <div className='row'>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>Address:</span>
                  <span className='fs-5 fw-bold mb-2 col'>
                    {patientData?.userAddress?.street || 'NA'}
                  </span>
                </div>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>City:</span>
                  <span className='fs-5 fw-bold mb-2 col'>
                    {patientData?.userAddress?.city || 'NA'}
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>District:</span>
                  <span className='fs-5 fw-bold mb-2 col'>
                    {patientData?.userAddress?.district || 'NA'}
                  </span>
                </div>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>ZipCode:</span>
                  <span className='fs-5 fw-bold mb-2 col'>
                    {patientData?.userAddress?.zipCode || 'NA'}
                  </span>
                </div>
              </div>
              <div className='row'>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>State:</span>
                  <span className='fs-5 fw-bold mb-2 col'>
                    {patientData?.userAddress?.state || 'NA'}
                  </span>
                </div>
                <div className='d-flex flex-row col-lg-6 mb-3 '>
                  <span className='fs-5 mb-2 col-5'>Country:</span>
                  <span className='fs-5 fw-bold mb-2 col'>
                    {patientData?.userAddress?.country || 'NA'}
                  </span>
                </div>
                <div className='row'>
                  <div className='d-flex flex-row col-lg-6 mb-3 '>
                    <span className='fs-5 mb-2 col-5'>Dob:</span>
                    <span className='fs-5 fw-bold mb-2 col'>
                      {patientData?.userProfile?.dob || 'NA'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}
