import {gql} from '@apollo/client'

export const GET_BUSINESS_STAFF_LIST = gql`
  query getBusinessStaffList($role: String) {
    getBusinessStaffList(role: $role) {
      userId
      businessName
      businessId
      userRoleId
      firstName
      lastName
      role
      mobileNumber
      appointmentDurationTime
      appointmentDurationUnit
      appointmentCancellationBeforeTime
      appointmentCancellationBeforeUnit
    }
  }
`
export const GET_DETAIL_BY_ID = gql`
  query getAppointmentTime($userRoleId: ID!) {
    getAppointmentTime(userRoleId: $userRoleId) {
      appointmentDurationTime
      appointmentDurationUnit
      appointmentCancellationBeforeTime
      appointmentCancellationBeforeUnit
    }
  }
`

export const EDIT_DETAIL = gql`
  mutation editAppointmentTime(
    $userRoleId: ID!
    $appointmentDurationTime: Int
    $appointmentDurationUnit: String
    $appointmentCancellationBeforetime: Int
    $appointmentCancellationBeforeUnit: String
  ) {
    editAppointmentTime(
      userRoleId: $userRoleId
      appDurInput: {
        appointmentDurationTime: $appointmentDurationTime
        appointmentDurationUnit: $appointmentDurationUnit
        appointmentCancellationBeforetime: $appointmentCancellationBeforetime
        appointmentCancellationBeforeUnit: $appointmentCancellationBeforeUnit
      }
    )
  }
`
export const GET_BUSINESS_DETAIL_BY_ID = gql`
  query getBusinessDetails($businessProfileId: ID) {
    getBusinessDetails(businessProfileId: $businessProfileId) {
      id
      userId
      applicationRole
      businessProfileRole
      businessProfileSubRole
      businessType
      businessId
      isUserVerified
      isBusinessVerified
      associatedName
      isApproved
      isActive
      verifiedDate
      userProfile {
        id
        userId
        userRoleId
        firstName
        lastName
        gender
        email
        dob
        aadhaarCard
        bloodGroup
        advanceDegrees
        profilePhoto
        preferredLanguage
        registeredBusinessType
        userTechDetails {
          mciNumber
          stateCouncil
          specialty
          doctorCertificates
          certifiedBy
          certifiedDate
          isDoctorCertified
        }
        isProfileCompleted
      }
      businessProfile {
        id
        businessProfileId
        businessRoleId
        businessName
        businessEmail
        businessType
        countryCode
        businessPhone
        businessRegisterDate
        applicationPolicyId
        policyAcceptedDate
        businessTechnicalDetails {
          mciNumber
          businessRegistrationNumber
          gstNumber
        }
      }
      businessAddress {
        id
        zipCode
        street
        city
        district
        state
        country
      }
      userAddress {
        id
        zipCode
        street
        city
        district
        state
        country
      }
      isPasswordExist
      doctorCertificates
    }
  }
`
