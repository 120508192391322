import {gql} from '@apollo/client'

export const GET_COUNTRIES_CODE = gql`
  query getCountries {
    getCountries {
      id
      name
      shortName
      phoneCode
      currency
      flagImage
      isActive
    }
  }
`

export const GET_INITIAL_DATA = gql`
  query getInitialData {
    getInitialData
  }
`

export const GET_BUSINESS_TYPES = gql`
  query getActiveBusinessTypes {
    getActiveBusinessTypes {
      id
      businessName
      isActive
    }
  }
`

export const SYSTEM_MODULE_LIST = gql`
  query getSystemModuleList($limit: Int!, $page: Int!) {
    getSystemModuleList(listFilter: {page: $page, limit: $limit}) {
      data {
        id
        moduleName
        modulePath
        moduleDescription
        moduleType
        modulePermissions {
          permissionName
          permissionSlug
          isActive
        }
      }
      total
      pageSize
      page
      totalPages
    }
  }
`
export const GET_SYSTEM_MODULE_BY_ID = gql`
  query getSystemModule($id: ID!) {
    getSystemModule(id: $id) {
      id
      moduleName
      modulePath
      moduleDescription
      moduleType
      modulePermissions {
        permissionName
        permissionSlug
        isActive
      }
    }
  }
`

export const GET_MY_PROFILE = gql`
  query getMyUserProfile($userId: ID) {
    getMyUserProfile(userId: $userId) {
      userProfile {
        firstName
        lastName
        email
        profilePhoto
        userTechDetails {
          specialty
        }
      }
      mobileNumber
    }
  }
`
