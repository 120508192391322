import {gql} from '@apollo/client'

export const GET_PATIENT_LIST_DASHBOARD = gql`
  query getScheduledAppointmentListByDate($date: DateTime!) {
    getScheduledAppointmentListByDate(date: $date) {
      id
      patientId
      firstName
      lastName
      profilePhoto
      appointmentType
      slotStart
      slotEnd
    }
  }
`
export const GET_PROFILE_SETUP_STATUS = gql`
  query getProfileSetupStatus {
    getProfileSetupStatus {
      stepName
      stepNumber
      screenName
      isCompleted
      isVerified
      isOptional
    }
  }
`

export const GET_VISTING_HOURS = gql`
  query getDoctorVisitingHours {
    getDoctorVisitingHours {
      weeklySlotsDetails {
        dayOfWeek
        timings {
          slotStart
          slotEnd
        }
        isClosed
        isOpen24Hours
      }
      monthlySlotsDetails {
        calendarType
        dates
        timings {
          slotStart
          slotEnd
        }
        repeatOption
        repeatRemark
      }
    }
  }
`
export const GET_BUSINESS_STEPS = gql`
  query getProfileSetupStatus {
    getProfileSetupStatus {
      stepName
      stepNumber
      screenName
      isCompleted
      isVerified
      isOptional
    }
  }
`
